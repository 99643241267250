<template>
	<div>
		<!-- :row-class-name="rowClassName" -->
		<a-table
			style="font-size: 12px"
			:columns="repayColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="repayPlanDataSource"
			:pagination="false"
			:scroll="{
				x: true
			}"
			bordered
			class="repayments-plan-list-table"
		>
			<template #title>
				<p class="title"> {{ !$Util.isEmpty(loanType) ? $t(`table.applyInfo.financialLabel.loanMap.${loanType}`) : 'logbook Loan/Asset Finance' }}</p>
			</template>
			<span slot="other" slot-scope="obj">
				<p class="link" @click="ShowModal(obj, 'Due')">{{ Number(obj.otherFeeDueTal).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Paid')">{{ Number(obj.otherFeePaidTal).toFixed(2) }}</p>
				<p>{{ Number(obj.otherFeeExtraPaid).toFixed(2) }}</p>
				<p class="link" @click="ShowModal(obj, 'Outstanding')">{{ Number(obj.otherFeeOutstandingTal).toFixed(2) }}</p>
			</span>
		</a-table>
		<a-tabs v-if="insurancePlanDataSource.length > 0" :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane :tab="'IPF ' + (insurancePlanDataSource.length - index)" v-for="(insurInfo, index) in insurancePlanDataSource" :key="index">
				<a-table
					style="font-size: 12px; margin: 10px 0"
					:columns="insuColumns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="insurInfo"
					:pagination="false"
					:scroll="{
						x: true
					}"
					bordered
					class="repayments-plan-list-table"
				>
					<template #title>
						<p class="title">{{ $t(`table.applyInfo.financialLabel.insurancePreFund`) }}</p>
					</template>
				</a-table>
			</a-tab-pane>
		</a-tabs>
		<a-tabs v-if="emergencyPlanDataSource.length > 0" :defaultActiveKey="0" type="card" :animated="true" style="margin-top: 20px">
			<a-tab-pane
				:tab="$t(`table.applyInfo.financialLabel.loanMap.${emergencyInfo[0].loanType}`)"
				v-for="(emergencyInfo, index) in emergencyPlanDataSource"
				:key="index"
			>
				<a-table
					style="font-size: 12px; margin: 10px 0"
					:columns="insuColumns"
					size="small"
					:rowKey="(record, index) => index"
					:dataSource="emergencyInfo"
					:pagination="false"
					:scroll="{
						x: true
					}"
					bordered
				>
					<span slot="title2">
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.should') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.already') }}
						</p>
						<p class="p_list">
							{{ $t('table.finance.cloumRepayment.tobe') }}
						</p>
					</span>
				</a-table>
			</a-tab-pane>
		</a-tabs>
		<a-table
			v-if="overpaidDetail"
			:columns="detailsColumns"
			size="small"
			:rowKey="(record, index) => index"
			:dataSource="overpaidDetail.repays"
			:pagination="false"
			bordered
			class="border-right"
		>
			<b slot="title">{{ $t(`table.applyInfo.financialLabel.overpaymentDetails`) }}</b>
			<span slot="receipt" slot-scope="obj">
				<template v-if="obj.transactionFiles">
					<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(obj.transactionFiles.split('?')[0].split('.').pop().toLowerCase())">
						<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" alt="" width="100" height="50" />
					</viewer>
					<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, obj.transactionFiles)" target="_blank">{{
						obj.transactionFiles.split('/')[obj.transactionFiles.split('/').length - 1]
					}}</a>
				</template>
				<template v-else-if="obj.transactionFiless">
					<span v-for="(img, index) in obj.transactionFiless" :key="index">
						<viewer v-if="['png', 'jpg', 'jpeg', 'gif'].includes(img.split('?')[0].split('.').pop().toLowerCase())">
							<img :src="$Util.getObsImageUrl($store.state.pageState.authObj, img)" alt="" width="100" height="50" />
						</viewer>
						<a v-else :href="$Util.getObsImageUrl($store.state.pageState.authObj, img)" target="_blank">{{
							img.split('/')[img.split('/').length - 1]
						}}</a>
						<br />
					</span>
				</template>
				<template v-else>-</template>
			</span>
		</a-table>
		<a-modal v-drag-modal v-model="FeeModal.show" :footer="false" width="600px" :title="$t(`table.finance.customerFee.cloum.otherFee`)">
			<a-table
				style="font-size: 12px"
				:columns="FeeModal.columns"
				size="small"
				:rowKey="(record, index) => index"
				:dataSource="FeeModal.dataSource"
				:pagination="false"
				bordered
			></a-table>
		</a-modal>
	</div>
</template>
<script>
export default {
	props: ['loanType', 'repayPlan', 'insurancePlans', 'emergencyPlans', 'overpaidDetail'],
	data() {
		return {
			FeeModal: {
				show: false,
				columns: [
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.detail'),
						align: 'center',
						dataIndex: 'feeName'
					},
					{
						// 费用金额
						title: this.$t('table.finance.customerFee.cloum.amount'),
						align: 'center',
						dataIndex: 'feeAmount',
						width: 100
					}
				],
				dataSource: []
			},
			repayPlanDataSource: [],
			insurancePlanDataSource: [],
			emergencyPlanDataSource: []
		}
	},
	computed: {
		repayColumns() {
			const repayColumnsList = [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
					fixed: 'left'
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 120,
					fixed: 'left'
				},
				{
					title: 'Actual Repayment Date',
					dataIndex: 'actualRepayDate',
					align: 'center',
					width: 120,
					fixed: 'left'
				},
				{
					align: 'center',
					width: 180,
					fixed: 'left',
					customRender: () => {
						return (
							<span>
								<p>{this.$t('table.finance.cloumRepayment.should')}</p> <p>{this.$t('table.finance.cloumRepayment.already')}</p>
								<p>{this.$t('table.finance.cloumRepayment.dscountAmount')}</p> <p>{this.$t('table.finance.cloumRepayment.tobe')}</p>
							</span>
						)
					}
				},
				{
					// 本金
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p> <p>{obj.principalPaid.toFixed(2)}</p>
								<p>0.00</p> <p>{obj.principalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 其他
					title: this.$t('table.finance.cloumRepayment.other'),
					scopedSlots: { customRender: 'other' },
					align: 'center'
				},
				{
					// 罚息
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p> <p>{obj.penaltyPaid.toFixed(2)}</p>
								<p>{obj.penaltyExtraPaid.toFixed(2)}</p> <p>{obj.penaltyOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 月度罚息
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p> <p>{obj.interestPaid.toFixed(2)}</p>
								<p>{obj.interestExtraPaid.toFixed(2)}</p> <p>{obj.interestOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					// 总额
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p> <p>{obj.totalPaid.toFixed(2)}</p> <p>{obj.totalExtraPaid.toFixed(2)}</p>
								<p>{obj.totalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
			if (this.loanType === 4) {
				repayColumnsList.splice(5, 0, {
					// GPS费用
					title: this.$t('table.finance.cloumRepayment.gpsFee'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
								<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				})
			}
			if (this.loanType !== 3 && this.loanType !== 4) {
				repayColumnsList.splice(
					5,
					0,
					{
						// GPS费用
						title: this.$t('table.finance.cloumRepayment.gpsFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.trackingFeeDue.toFixed(2)}</p> <p>{obj.trackingFeePaid.toFixed(2)}</p>
									<p>{obj.trackingFeeExtraPaid.toFixed(2)}</p> <p>{obj.trackingFeeOutstanding.toFixed(2)}</p>
								</span>
							)
						}
					},
					{
						title: this.$t('table.applyInfo.loanApplyLabel.maintenanceFee'),
						align: 'center',
						customRender: (obj) => {
							return (
								<span>
									<p>{obj.maintenanceFeeDue.toFixed(2)}</p> <p>{obj.maintenanceFeePaid.toFixed(2)}</p>
									<p>{obj.maintenanceFeeExtraPaid.toFixed(2)}</p> <p>{obj.maintenanceFeeOutstanding.toFixed(2)}</p>
								</span>
							)
						}
					}
				)
			}
			return repayColumnsList
		},
		insuColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.trem'),
					align: 'center',
					dataIndex: 'installments',
					width: 60,
					fixed: 'left'
				},
				{
					title: 'Scheduled Repayment Date',
					dataIndex: 'duedate',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					title: 'Actual Repayment Date',
					dataIndex: 'actualRepayDate',
					align: 'center',
					width: 150,
					fixed: 'left'
				},
				{
					align: 'center',
					width: 180,
					fixed: 'left',
					customRender: () => {
						return (
							<span>
								<p>{this.$t('table.finance.cloumRepayment.should')}</p> <p>{this.$t('table.finance.cloumRepayment.already')}</p>{' '}
								<p>{this.$t('table.finance.cloumRepayment.tobe')}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.amount'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.principalDue.toFixed(2)}</p> <p>{obj.principalPaid.toFixed(2)}</p> <p>{obj.principalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.penalty'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.penaltyDue.toFixed(2)}</p> <p>{obj.penaltyPaid.toFixed(2)}</p> <p>{obj.penaltyOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.monthly'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.interestDue.toFixed(2)}</p> <p>{obj.interestPaid.toFixed(2)}</p> <p>{obj.interestOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					align: 'center',
					customRender: (obj) => {
						return (
							<span>
								<p>{obj.totalDue.toFixed(2)}</p> <p>{obj.totalPaid.toFixed(2)}</p> <p>{obj.totalOutstanding.toFixed(2)}</p>
							</span>
						)
					}
				}
			]
		},
		detailsColumns() {
			return [
				{
					title: this.$t('table.finance.cloumRepayment.referenceId'),
					align: 'center',
					dataIndex: 'paymentId'
				},
				{
					title: this.$t('table.finance.cloumRepayment.time'),
					align: 'center',
					dataIndex: 'transactionDate'
				},
				{
					title: this.$t('table.finance.cloumRepayment.sum'),
					dataIndex: 'amount',
					align: 'center'
				},
				{
					title: this.$t('table.finance.lenging.cloums.receipt'),
					align: 'center',
					scopedSlots: { customRender: 'receipt' }
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.totalRefundAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.totalRefundAmount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				},
				{
					title: this.$t(`table.applyInfo.financialLabel.remainingOverpaymentAmount`),
					align: 'center',
					customRender: (text, row, index) => {
						if (index === 0) {
							return {
								children: <span>{this.overpaidDetail.amount}</span>,
								attrs: { rowSpan: this.overpaidDetail.repays.length }
							}
						} else {
							return {
								attrs: { rowSpan: 0 }
							}
						}
					}
				}
			]
		}
	},
	methods: {
		// rowClassName(_record, index) {
		// 	return _record.changed ? 'balance' : ''
		// },
		// 其他弹窗
		ShowModal(obj, type) {
			const otherFee = [
				{
					feeName: this.$t('table.finance.cloumRepayment.checkFee'),
					feeAmount: obj[`checkReturnFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.cost'),
					feeAmount: obj[`repossessionFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.costCode'),
					feeAmount: obj[`repossessionOrderFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.settle'),
					feeAmount: obj[`logbookFee${type}`]
				},
				{
					feeName: this.$t('table.finance.cloumRepayment.other'),
					feeAmount: obj[`otherFee${type}`]
				}
			]
			this.FeeModal.dataSource = obj.otherFee ? [...obj.otherFee.details, ...otherFee] : [...otherFee]
			this.FeeModal.show = true
		}
	},
	created() {
		this.$nextTick(() => {
			// 本金数据
			this.repayPlan.map((item) => {
				item.otherFeeDueTal = 0
				item.otherFeePaidTal = 0
				item.otherFeeOutstandingTal = 0
				item.totalExtraPaid = 0
				// 其他费用集合
				item.otherFeeDueTal += item.otherFeeDue + item.checkReturnFeeDue + item.repossessionFeeDue + item.repossessionOrderFeeDue + item.logbookFeeDue
				item.otherFeePaidTal +=
					item.otherFeePaid + item.checkReturnFeePaid + item.repossessionFeePaid + item.repossessionOrderFeePaid + item.logbookFeePaid
				item.otherFeeOutstandingTal +=
					item.otherFeeOutstanding +
					item.checkReturnFeeOutstanding +
					item.repossessionFeeOutstanding +
					item.repossessionOrderFeeOutstanding +
					item.logbookFeeOutstanding
				item.totalExtraPaid =
					item.trackingFeeExtraPaid + item.interestExtraPaid + item.penaltyExtraPaid + item.otherFeeExtraPaid + item.maintenanceFeeExtraPaid
			})
			const repayPlanTotal = {
				installments: 'Total',
				principalDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.principalDue
				}, 0),
				principalPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.principalPaid
				}, 0),
				principalOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.principalOutstanding
				}, 0),
				maintenanceFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.maintenanceFeeDue
				}, 0),
				maintenanceFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.maintenanceFeePaid
				}, 0),
				maintenanceFeeExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.maintenanceFeeExtraPaid
				}, 0),
				maintenanceFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.maintenanceFeeOutstanding
				}, 0),
				trackingFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.trackingFeeDue
				}, 0),
				trackingFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.trackingFeePaid
				}, 0),
				trackingFeeExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.trackingFeeExtraPaid
				}, 0),
				trackingFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.trackingFeeOutstanding
				}, 0),
				checkReturnFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.checkReturnFeeDue
				}, 0),
				checkReturnFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.checkReturnFeePaid
				}, 0),
				checkReturnFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.checkReturnFeeOutstanding
				}, 0),
				repossessionFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionFeeDue
				}, 0),
				repossessionFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionFeePaid
				}, 0),
				repossessionFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionFeeOutstanding
				}, 0),
				repossessionOrderFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionOrderFeeDue
				}, 0),
				repossessionOrderFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionOrderFeePaid
				}, 0),
				repossessionOrderFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.repossessionOrderFeeOutstanding
				}, 0),
				otherFeeDueTal: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeeDueTal
				}, 0),
				otherFeePaidTal: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeePaidTal
				}, 0),
				otherFeeOutstandingTal: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeeOutstandingTal
				}, 0),
				otherFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeeDue
				}, 0),
				otherFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeePaid
				}, 0),
				otherFeeExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeeExtraPaid
				}, 0),
				otherFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.otherFeeOutstanding
				}, 0),
				penaltyDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.penaltyDue
				}, 0),
				penaltyPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.penaltyPaid
				}, 0),
				penaltyExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.penaltyExtraPaid
				}, 0),
				penaltyOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.penaltyOutstanding
				}, 0),
				interestDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.interestDue
				}, 0),
				interestPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.interestPaid
				}, 0),
				interestExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.interestExtraPaid
				}, 0),
				interestOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.interestOutstanding
				}, 0),
				logbookFeeDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.logbookFeeDue
				}, 0),
				logbookFeePaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.logbookFeePaid
				}, 0),
				logbookFeeOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.logbookFeeOutstanding
				}, 0),
				totalDue: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.totalDue
				}, 0),	
				totalPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.totalPaid
				}, 0),
				totalExtraPaid: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.totalExtraPaid
				}, 0),
				totalOutstanding: this.repayPlan.reduce((pre, cur) => {
					return pre + cur.totalOutstanding
				}, 0)
			}
			if (this.repayPlan.length > 0) {
				repayPlanTotal.duedate = this.repayPlan.at(-1).duedate
				this.repayPlanDataSource = this.repayPlan.concat([repayPlanTotal])
			}
			if (this.insurancePlans) {
				this.insurancePlans.forEach((element) => {
					const insuranceTotal = {
						installments: 'Total',
						principalDue: element.reduce((pre, cur) => {
							return pre + cur.principalDue
						}, 0),
						principalPaid: element.reduce((pre, cur) => {
							return pre + cur.principalPaid
						}, 0),
						principalOutstanding: element.reduce((pre, cur) => {
							return pre + cur.principalOutstanding
						}, 0),
						interestDue: element.reduce((pre, cur) => {
							return pre + cur.interestDue
						}, 0),
						interestPaid: element.reduce((pre, cur) => {
							return pre + cur.interestPaid
						}, 0),
						interestOutstanding: element.reduce((pre, cur) => {
							return pre + cur.interestOutstanding
						}, 0),
						penaltyDue: element.reduce((pre, cur) => {
							return pre + cur.penaltyDue
						}, 0),
						penaltyPaid: element.reduce((pre, cur) => {
							return pre + cur.penaltyPaid
						}, 0),
						penaltyOutstanding: element.reduce((pre, cur) => {
							return pre + cur.penaltyOutstanding
						}, 0),
						totalDue: element.reduce((pre, cur) => {
							return pre + cur.totalDue
						}, 0),
						totalPaid: element.reduce((pre, cur) => {
							return pre + cur.totalPaid
						}, 0),
						totalOutstanding: element.reduce((pre, cur) => {
							return pre + cur.totalOutstanding
						}, 0)
					}
					if (element.length > 0) {
						insuranceTotal.duedate = element.at(-1).duedate
						element = element.push(insuranceTotal)
					}
				})
				this.insurancePlanDataSource = this.insurancePlans
			}
			if (this.emergencyPlans) {
				this.emergencyPlans.forEach((element) => {
					const insuranceTotal = {
						installments: 'Total',
						principalDue: element.reduce((pre, cur) => {
							return pre + cur.principalDue
						}, 0),
						principalPaid: element.reduce((pre, cur) => {
							return pre + cur.principalPaid
						}, 0),
						principalOutstanding: element.reduce((pre, cur) => {
							return pre + cur.principalOutstanding
						}, 0),
						interestDue: element.reduce((pre, cur) => {
							return pre + cur.interestDue
						}, 0),
						interestPaid: element.reduce((pre, cur) => {
							return pre + cur.interestPaid
						}, 0),
						interestOutstanding: element.reduce((pre, cur) => {
							return pre + cur.interestOutstanding
						}, 0),
						penaltyDue: element.reduce((pre, cur) => {
							return pre + cur.penaltyDue
						}, 0),
						penaltyPaid: element.reduce((pre, cur) => {
							return pre + cur.penaltyPaid
						}, 0),
						penaltyOutstanding: element.reduce((pre, cur) => {
							return pre + cur.penaltyOutstanding
						}, 0),
						totalDue: element.reduce((pre, cur) => {
							return pre + cur.totalDue
						}, 0),
						totalPaid: element.reduce((pre, cur) => {
							return pre + cur.totalPaid
						}, 0),
						totalOutstanding: element.reduce((pre, cur) => {
							return pre + cur.totalOutstanding
						}, 0)
					}
					if (element.length > 0) {
						insuranceTotal.duedate = element.at(-1).duedate
						element = element.push(insuranceTotal)
					}
				})
				this.emergencyPlanDataSource = this.emergencyPlans
			}
		})
	}
}
</script>
<style lang="less">
.repayments-plan-list-table {
	.title {
		font-size: 14px;
		color: rgba(0, 0, 0, 0.85);
		font-weight: bold;
	}
	.p_list {
		text-align: center;
	}
	.link {
		cursor: pointer;
		color: #40a9ff;
	}
	.balance {
		background: #e6f7ff;
	}
}
</style>
